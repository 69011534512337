"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openBoostAppOrStore = exports.isWebview = exports.getOperatingSystem = exports.generateBoostPricingLink = exports.checkIsDomainAdmin = void 0;
var _consts = require("../consts");
var checkIsDomainAdmin = exports.checkIsDomainAdmin = function checkIsDomainAdmin() {
  var domain = window.location.hostname;
  return Boolean(domain.match(/^admin.*\.tomocredit\.com$/)) || process.env.REACT_APP_IS_ADMIN;
};
var generateBoostPricingLink = exports.generateBoostPricingLink = function generateBoostPricingLink(queryParameters) {
  return "".concat(process.env.REACT_APP_DOMAIN, "/boost-pricing").concat(queryParameters !== null && queryParameters !== void 0 && queryParameters.size ? "?".concat(queryParameters.toString()) : "");
};
var isWebview = exports.isWebview = function isWebview() {
  var userAgent = navigator.userAgent;

  // Detect iOS WebView
  var iosWebView = /iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent);

  // Detect Android WebView
  var androidWebView = /wv/.test(userAgent) || /Android.*AppleWebKit/.test(userAgent) && !/Chrome/.test(userAgent);
  return iosWebView || androidWebView;
};
var getOperatingSystem = exports.getOperatingSystem = function getOperatingSystem() {
  var userAgent = window.navigator.userAgent.toLowerCase();
  if (/iphone|ipad|ipod/i.test(userAgent)) return "iOS";
  if (/android/i.test(userAgent)) return "Android";
  if (/mac/i.test(userAgent) && !/iphone|ipad|ipod/i.test(userAgent)) {
    return "Mac OS";
  }
  if (/win/i.test(userAgent)) return "Windows";
  if (/linux/i.test(userAgent)) return "Linux";
  return "Unknown";
};
var openBoostAppOrStore = exports.openBoostAppOrStore = function openBoostAppOrStore(args) {
  var _ref = args || {},
    _ref$deferredUrl = _ref.deferredUrl,
    deferredUrl = _ref$deferredUrl === void 0 ? "" : _ref$deferredUrl;
  var os = getOperatingSystem();
  if (!["Android", "iOS"].includes(os)) {
    return;
  }
  var tryOpenApp = function tryOpenApp() {
    var universalLink = "".concat(_consts.BOOST_APP_SCHEME).concat(deferredUrl);
    var fallbackUrl = os === "iOS" ? _consts.BOOST_APP_URL_IOS : _consts.BOOST_APP_URL_ANDROID;

    // Try to open the app using the Universal Link
    window.location.href = universalLink;

    // Fallback after a short delay if the app doesn't open
    setTimeout(function () {
      window.location.href = fallbackUrl;
    }, 2000);
  };

  // if user does not have the app installed, on iOS, users see an ugly native modal saying
  // "Something went wrong, the application could not be opened"
  // to avoid this, we use a hacky solution.
  // first, we need to add an empty frame to the page
  // second, we call the actual function that will be executed in the iframe
  // that script will try to open the app using the app scheme
  // if the app does not open, the script will try to open the app store link
  var iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  iframe.height = "1";
  iframe.width = "1";
  iframe.id = "tomo-boost-iframe";
  iframe.onload = tryOpenApp;
  document.body.appendChild(iframe);
};