"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidEmail = void 0;
// General Email Regex RFC 5322 Standard
var emailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var isValidEmail = exports.isValidEmail = function isValidEmail(email) {
  return emailValidationRegex.test(email);
};