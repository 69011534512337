"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LEAD_SOURCES = exports.BOOST_APP_URL_IOS = exports.BOOST_APP_URL_ANDROID = exports.BOOST_APP_SCHEME = exports.ANALYTICS_EVENTS = void 0;
/* LEAD */
var LEAD_SOURCES = exports.LEAD_SOURCES = {
  BOOST_LANDING: "BOOST_LANDING",
  BOOST_POPUP: "BOOST_POPUP",
  BOOST_PRICING: "BOOST_PRICING",
  CARD_LANDING: "CARD_LANDING",
  PLATFORM_ONBOARDING: "PLATFORM_ONBOARDING",
  KOSMOFI_LANDING: "KOSMOFI_LANDING",
  CREDIT_MONITORING_DASHBOARD: "CREDIT_MONITORING_DASHBOARD",
  LANDING_BOOST_CHECKOUT: "LANDING_BOOST_CHECKOUT",
  DASHBOARD_BOOST_CHECKOUT: "DASHBOARD_BOOST_CHECKOUT",
  DASHBOARD_BOOST_UPGRADE_POPUP: "DASHBOARD_BOOST_UPGRADE_POPUP",
  DASHBOARD_BOOST_LOOKBACK: "dashboard_boost_lookback"
};
var ANALYTICS_EVENTS = exports.ANALYTICS_EVENTS = {
  NEW_LEAD: "tomo_new_lead"
};
var BOOST_APP_SCHEME = exports.BOOST_APP_SCHEME = "tomo-boost://";
var BOOST_APP_URL_IOS = exports.BOOST_APP_URL_IOS = "https://apps.apple.com/app/6504301873";
var BOOST_APP_URL_ANDROID = exports.BOOST_APP_URL_ANDROID = "https://play.google.com/store/apps/details?id=com.tomocredit.boost";