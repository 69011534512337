import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import axios from "axios";
import { checkIsDomainAdmin } from "@tomocredit/common-fe/utils";
import './layout.css'; // order matters! must be before generated.css
import "./generated.css"; // order matters! must be after layout.css

// Init Sentry
// eslint-disable-next-line no-unused-vars
// import Sentry from "./api/sentry";
// import { initRemoteConfig } from "./api/firebase/remote-config";

const App = React.lazy(() => import("./App"));
const AppBoost = React.lazy(() => import("./AppBoost"));
const AppIQ = React.lazy(() => import("./AppIQ"));
const AppKosmofi = React.lazy(() => import("./AppKosmofi"));

// Set axios base URL
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

// Init Firebase RemoteConfig
// initRemoteConfig(); // temporarily disabled

const domain = window.location.hostname;
const isAdminDomain = checkIsDomainAdmin();
if (isAdminDomain) {
  // match domain/dashboard
  if (!window.location.pathname.startsWith("/dashboard")) {
    // Redirect to admin dashboard login
    window.location.replace(`/dashboard/login`);
  }
}

const isBoostApp = domain.endsWith("tomoboost.com");
const isKosmofiApp = domain.endsWith("kosmofi.com");
const isIQApp = domain.endsWith("tomoiq.ai") || domain.endsWith("tomoiq.com");

let FinalApp = <App />;
if (isBoostApp) {
  FinalApp = <AppBoost />;
}
if (isKosmofiApp) {
  FinalApp = <AppKosmofi />;
}
if (isIQApp) {
  FinalApp = <AppIQ />;
}

// This is the React 18 way to creating the new root: https://react.dev/blog/2022/03/08/react-18-upgrade-guide
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Suspense fallback={null}>{FinalApp}</Suspense>);
