"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.snakecaseToTitlecase = exports.capitalize = void 0;
var capitalize = exports.capitalize = function capitalize(s) {
  if (!s) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
var IGNORED_WORDS = new Set(["a", "an", "the", "in", "of", "on", "for", "by", "and", "or", "but", "nor"]);
var snakecaseToTitlecase = exports.snakecaseToTitlecase = function snakecaseToTitlecase(s) {
  if (!s) return "";
  return s.split("_").map(function (w, index) {
    if (index === 0) return capitalize(w);
    return IGNORED_WORDS.has(w) ? w : capitalize(w);
  }).join(" ");
};